import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Nav, Row, Overlay } from 'react-bootstrap';
import { Col, Tabs, Tab, Card, Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AuthenticationMain from '../authentication/authenticationMain';
import AuthenticationLdap from '../authentication/authenticationLdap';
import AuthenticationCrue from '../authentication/authenticationCrue';
import AuthenticationSaml from '../authentication/authenticationSaml';
import AuthenticationUnad from '../authentication/authenticationUnad';
import AuthorizationForm from '../authorization/authorizationForm';
import GroupList from '../groups/groupList';
import NotificationList from '../notification/notificationList';
import RolemapForm from '../rolemap/rolemapForm';
import RolesvcForm from '../rolesvc/rolesvcForm';
import IdentityForm from '../identities/identityForm';
import CredentialList from '../credential/credentialList';
import ConfigIdentifiers from '../config/configIdentifiers';
import ConfigColors from '../config/configColors';
import ConfigCurrency from '../config/configCurrency';
import ConfigLogin from '../config/configLogin';
import ConfigPublicAccessAuth from '../config/configPublicAccessAuth';
import ConfigPublicAccessConf from '../config/configPublicAccessConf';
import ConfigTwitter from '../config/configTwitter';
import ConfigPayment from '../config/configPayment';
import ConfigEnrollment from '../config/configEnrollment';
import ConfigTS from '../config/configTS';
import ConfigEconomic from '../config/configEconomic';
import ConfigTraining from '../config/configTraining';
import ConfigAbsences from '../config/configAbsences';
import ConfigAlumni from '../config/configAlumni';
import ConfigTutors from '../config/configTutors';
import ConfigDocumentManager from '../config/configDocumentManager';
import ConfigVirtualCard from '../config/configVirtualCard';
import ConfigCDU from '../config/configCDU';
import ConfigRateAndShare from '../config/configRateAndShare';
import ConfigHomePage from '../config/configHomePage';
import ConfigDownload from '../config/configDownload';
import ConfigScreens from '../config/configScreens';
import ConfigLanguages from '../config/configLanguages';
import ConfigWidgets from '../config/configWidgets';
import ConfigAttendance from '../config/configAttendance';
import SelectUCRoles from '../widgets/selectUCRoles';
import SelectTimeZone from '../widgets/selectTimeZone';
import SelectVersion from '../widgets/selectVersion'
import SwitchForm from '../widgets/switchForm';
import { orderByName, getShowedSections } from '../../helpers/utils';
import { isAdminRole } from '../../helpers/roles';
import { notificationTypes } from '../../data/notification';
import { timezones } from '../../data/timezones';
import ConfigOrder from '../config/configOrder';
import CatalogActions from '../catalog/catalogActions';
import { isValidUuid } from '../../helpers/utils';

const UniversityItem = ({
  universityId,
  name,
  alias,
  address,
  secretKey,
  authentication,
  authType,
  authParams,
  authHost,
  authStack,
  authTracking,
  cmsHost,
  psHost,
  authorization,
  academic,
  economic,
  investigation,
  humanresource,
  securetitle,
  general,
  identity,
  service,
  notificationKey,
  notificationType,
  notificationExp,
  iosVendorNumber,
  iosIssuerId,
  iosAppStoreKey,
  iosSecretKey,
  iosAlias,
  authorizations,
  rolemaps,
  rolesvcs,
  services,
  generals,
  academics,
  economics,
  investigations,
  humanresources,
  securetitles,
  rolemap,
  rolesvc,
  roleuc,
  identities,
  notification,
  notifications,
  timezone,
  zonename,
  androidProjectId,
  androidPrivateKeyId,
  androidPrivateKey,
  androidClientEmail,
  androidClientId,
  androidAlias,
  androidCertUrl,
  androidBucketName,
  emailTemplate,
  cruePrivateKey,
  crueUserMap,
  configApp,
  isUpdate,
  crueAppId,
  crueApiKey,
  crueApiKeyCreator,
  onSubmit,
  onDuplicate,
  onDelete,
  onSession,
  versions,
  portalVersion,
  awsVersion,
}) => {
  const [show, setShow] = useState(false);
  const [generateApiKeys, setGenerateApiKeys] = useState(true);
  const target = useRef(null);
  const rolesuc =
    generals && generals.filter((item) => item.code === 'CDU_CARD');
  const code = `${process.env.REACT_APP_CODE}`;
  const showSections = getShowedSections(code);
  const sectionName = {
    platformRoles: {
      APP: 'Roles de la APP',
      PS: 'Roles del Portal',
      ALUMNI: 'Roles de Alumni',
    },
  };

  const items = useSelector((state) => state.university.items);

  const [formData, setFormData] = useState({
    name: '',
    universityId: '',
    secretKey: '',
  });

  useEffect(() => {
    setFormData({
      name: name,
      universityId: universityId,
      secretKey: secretKey,
    });
  }, [name, universityId, secretKey]);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm(formData);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      onSubmit(e);
    }
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.name) {
      errors.name = 'El nombre de la universidad es obligatorio';
    }

    if (!generateApiKeys) {
      if (!isValidUuid(data.universityId)) {
        errors.universityId =
          'La clave de la universidad debe ser un UUID válido';
      } else {
        // dispatch(fetchUniversities()) hacerlo sincrono
        if (items.some((item) => item.universityId === data.universityId)) {
          errors.universityId =
            'La clave de la universidad introducida ya existe';
        }
      }
    }

    if (!generateApiKeys) {
      if (!isValidUuid(data.secretKey)) {
        errors.secretKey =
          'El secreto de la universidad debe ser un UUID válido';
      } else {
        // dispatch(fetchUniversities()) hacerlo sincrono
        if (items.some((item) => item.secretKey === data.secretKey)) {
          errors.secretKey =
            'El secreto de la universidad introducida ya existe';
        }
      }
    }

    return errors;
  };

  return (
    <Form
      id="university-item"
      className="py-2"
      onSubmit={handleSubmit}
      method="post"
    >
      <Row>
        <Col>
          <h1 className="title">
            <Nav.Link as={Link} to="/">
              <span className="icon-arrow"></span>
              {isUpdate ? name : 'Crear universidad'}
            </Nav.Link>
          </h1>
        </Col>
        <Col className="text-end">
          {isUpdate && (
            <>
              <Button
                variant="link"
                ref={target}
                onClick={() => setShow(!show)}
              >
                <span className="icon-vertical-dots"></span>
              </Button>
              <Overlay
                target={target.current}
                show={show}
                placement="bottom-start"
              >
                {({ placement, arrowProps, show: _show, popper, ...props }) => (
                  <div
                    {...props}
                    style={{
                      backgroundColor: 'rgba(255,255,255,1)',
                      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
                      padding: '12px',
                      color: '#006FA2',
                      borderRadius: 1,
                      ...props.style,
                    }}
                  >
                    <Button
                      className="btn btn-actions"
                      variant="link"
                      onClick={() => setShow(!show)}
                      form="university-item"
                      value="export"
                      type="submit"
                      style={{ color: '#006FA2' }}
                    >
                      Exportar servicios
                    </Button>
                    {isAdminRole() && (
                      <Button
                        className="btn btn-actions"
                        variant="link"
                        onClick={(id) => onDuplicate(universityId)}
                        style={{ color: '#006FA2' }}
                      >
                        Duplicar universidad
                      </Button>
                    )}
                    <Button
                      className="btn btn-actions"
                      variant="link"
                      onClick={() => setShow(!show)}
                      form="university-item"
                      value="update"
                      type="submit"
                      style={{ color: '#006FA2' }}
                    >
                      Actualizar baseUrl
                    </Button>
                    <Button
                      className="btn btn-actions"
                      variant="link"
                      onClick={(id) => onSession(universityId)}
                      style={{ color: '#006FA2' }}
                    >
                      Forzar cierre de sesión
                    </Button>
                    {isAdminRole() && (
                      <Button
                        className="btn btn-actions"
                        variant="link"
                        onClick={(id) => onDelete(universityId)}
                        style={{ color: '#D2423B' }}
                      >
                        Eliminar universidad
                      </Button>
                    )}
                  </div>
                )}
              </Overlay>
            </>
          )}
          <Button
            className="float-end"
            name="btn-save"
            value="save"
            type="submit"
          >
            Guardar cambios
          </Button>
        </Col>
      </Row>
      <Form.Control
        name="universityId"
        type="hidden"
        value={formData.universityId}
      />
      <Tabs
        variant="pills"
        defaultActiveKey="settings"
        transition={false}
        id="noanim-tab-example"
      >
        <Tab
          className=""
          eventKey="settings"
          title={code === 'PS' ? 'Entorno' : 'Ajustes'}
        >
          <Accordion
            defaultActiveKey={isUpdate ? '5' : ['0', '5']}
            alwaysOpen={!isUpdate}
          >
            {showSections['generalData'] && (
              <>
                <Card>
                  <Accordion.Item eventKey="0">
                    <Card.Header>
                      <Accordion.Button as={Button} variant="block text-start">
                        Datos generales
                      </Accordion.Button>
                    </Card.Header>
                    <Accordion.Body>
                      <Card.Body>
                        <Form.Group controlId="name">
                          <Form.Label>Nombre</Form.Label>
                          <Form.Control
                            name="name"
                            type="text"
                            value={formData.name}
                            onChange={handleChange}
                          />
                          {errors.name && (
                            <span className="error-message">{errors.name}</span>
                          )}
                        </Form.Group>
                        <Form.Group controlId="alias">
                          <Form.Label>Alias</Form.Label>
                          <Form.Control
                            name="alias"
                            type="text"
                            defaultValue={alias}
                          />
                        </Form.Group>
                        <Form.Group controlId="address">
                          <Form.Label>Dirección</Form.Label>
                          <Form.Control
                            name="address"
                            type="text"
                            defaultValue={address}
                          />
                        </Form.Group>
                        <Form.Group controlId="timezone">
                          <Form.Label>Uso horario</Form.Label>
                          <Form.Control
                            name="timezone"
                            type="text"
                            defaultValue={timezone}
                          />
                        </Form.Group>
                        <Form.Group controlId="zonename">
                          <Form.Label>Zona horaria</Form.Label>
                          <SelectTimeZone
                            name="zonename"
                            value={zonename}
                            items={timezones}
                          />
                        </Form.Group>
                        <Form.Group controlId="cmsHost">
                          <Form.Label>Host CMS</Form.Label>
                          <Form.Control
                            name="cmsHost"
                            type="text"
                            defaultValue={cmsHost}
                          />
                        </Form.Group>
                        <Form.Group controlId="psHost">
                          <Form.Label>Host Portal</Form.Label>
                          <Form.Control
                            name="psHost"
                            type="text"
                            defaultValue={psHost}
                          />
                        </Form.Group>
                      </Card.Body>
                    </Accordion.Body>
                  </Accordion.Item>
                </Card>
              </>
            )}
            {isUpdate && (
              <>
                {showSections['communicationMailbox'] && (
                  <>
                    <Card>
                      <Accordion.Item eventKey="1">
                        <Card.Header>
                          <Accordion.Button
                            as={Button}
                            variant="block text-start"
                          >
                            Buzón comunicación
                          </Accordion.Button>
                        </Card.Header>
                        <Accordion.Body>
                          <Card.Body>
                            <Form.Group controlId="emailTemplate">
                              <Form.Label>Plantilla correo</Form.Label>
                              <Form.Control
                                name="emailTemplate"
                                as="textarea"
                                rows={6}
                                defaultValue={emailTemplate}
                              />
                            </Form.Group>
                          </Card.Body>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Card>
                  </>
                )}

                {showSections['notifications'] && (
                  <>
                    <Card>
                      <Accordion.Item eventKey="2">
                        <Card.Header>
                          <Accordion.Button
                            as={Button}
                            variant="block text-start"
                          >
                            Notificaciones
                          </Accordion.Button>
                        </Card.Header>
                        <Accordion.Body>
                          <Card.Body>
                            <Form.Label>Sistema de notificaciones</Form.Label>
                            <SwitchForm
                              name="notificationType"
                              value={notificationType}
                              defaultValue="general"
                              options={notificationTypes}
                            >
                              <div id="general" className="margin-top">
                                <Form.Group controlId="notificationKey">
                                  <Form.Label>Clave</Form.Label>
                                  <Form.Control
                                    name="notificationKey"
                                    type="text"
                                    defaultValue={notificationKey}
                                  />
                                </Form.Group>
                                <Form.Group controlId="notificationExp">
                                  <Form.Label>Expiración (días)</Form.Label>
                                  <Form.Control
                                    name="notificationExp"
                                    type="text"
                                    defaultValue={notificationExp}
                                  />
                                </Form.Group>
                              </div>
                              <div id="crue" className="margin-top">
                                <Form.Group controlId="crueAppId">
                                  <Form.Label>ID Aplicación</Form.Label>
                                  <Form.Control
                                    name="crueAppId"
                                    type="text"
                                    defaultValue={crueAppId}
                                  />
                                </Form.Group>
                                <Form.Group controlId="crueApiKey">
                                  <Form.Label>API Key</Form.Label>
                                  <Form.Control
                                    name="crueApiKey"
                                    type="text"
                                    defaultValue={crueApiKey}
                                  />
                                </Form.Group>
                                <Form.Group controlId="crueApiKeyCreator">
                                  <Form.Label>API Key Creator</Form.Label>
                                  <Form.Control
                                    name="crueApiKeyCreator"
                                    type="text"
                                    defaultValue={crueApiKeyCreator}
                                  />
                                </Form.Group>
                              </div>
                            </SwitchForm>
                          </Card.Body>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Card>
                  </>
                )}

                {showSections['iosExtractors'] && (
                  <>
                    <Card>
                      <Accordion.Item eventKey="3">
                        <Card.Header>
                          <Accordion.Button
                            as={Button}
                            variant="block text-start"
                          >
                            Extractores iOS
                          </Accordion.Button>
                        </Card.Header>
                        <Accordion.Body>
                          <Card.Body>
                            <Form.Group controlId="iosAlias">
                              <Form.Label>Alias iOS</Form.Label>
                              <Form.Control
                                name="iosAlias"
                                type="text"
                                defaultValue={iosAlias}
                              />
                            </Form.Group>
                            <Form.Group controlId="iosVendorNumber">
                              <Form.Label>Número del proveedor</Form.Label>
                              <Form.Control
                                name="iosVendorNumber"
                                type="text"
                                defaultValue={iosVendorNumber}
                              />
                            </Form.Group>
                            <Form.Group controlId="iosIssuerId">
                              <Form.Label>Identificador del emisor</Form.Label>
                              <Form.Control
                                name="iosIssuerId"
                                type="text"
                                defaultValue={iosIssuerId}
                              />
                            </Form.Group>
                            <Form.Group controlId="iosAppStoreKey">
                              <Form.Label>Clave</Form.Label>
                              <Form.Control
                                name="iosAppStoreKey"
                                type="text"
                                defaultValue={iosAppStoreKey}
                              />
                            </Form.Group>
                            <Form.Group controlId="iosSecretKey">
                              <Form.Label>Secreto</Form.Label>
                              <Form.Control
                                name="iosSecretKey"
                                as="textarea"
                                rows={3}
                                defaultValue={iosSecretKey}
                              />
                            </Form.Group>
                          </Card.Body>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Card>
                  </>
                )}

                {showSections['androidExtractors'] && (
                  <>
                    <Card>
                      <Accordion.Item eventKey="4">
                        <Card.Header>
                          <Accordion.Button
                            as={Button}
                            variant="block text-start"
                          >
                            Extractores Android
                          </Accordion.Button>
                        </Card.Header>
                        <Accordion.Body>
                          <Card.Body>
                            <Form.Group controlId="androidAlias">
                              <Form.Label>Alias Android</Form.Label>
                              <Form.Control
                                name="androidAlias"
                                type="text"
                                defaultValue={androidAlias}
                              />
                            </Form.Group>
                            <Form.Group controlId="androidProjectId">
                              <Form.Label>
                                Identificador del proyecto
                              </Form.Label>
                              <Form.Control
                                name="androidProjectId"
                                type="text"
                                defaultValue={androidProjectId}
                              />
                            </Form.Group>
                            <Form.Group controlId="androidPrivateKeyId">
                              <Form.Label>
                                Identificador de clave del proyecto
                              </Form.Label>
                              <Form.Control
                                name="androidPrivateKeyId"
                                type="text"
                                defaultValue={androidPrivateKeyId}
                              />
                            </Form.Group>
                            <Form.Group controlId="androidPrivateKey">
                              <Form.Label>
                                Clave privada del proyecto
                              </Form.Label>
                              <Form.Control
                                name="androidPrivateKey"
                                as="textarea"
                                rows={3}
                                defaultValue={androidPrivateKey}
                              />
                            </Form.Group>
                            <Form.Group controlId="androidClientEmail">
                              <Form.Label>Email del proyecto</Form.Label>
                              <Form.Control
                                name="androidClientEmail"
                                type="text"
                                defaultValue={androidClientEmail}
                              />
                            </Form.Group>
                            <Form.Group controlId="androidClientId">
                              <Form.Label>Identificador del cliente</Form.Label>
                              <Form.Control
                                name="androidClientId"
                                type="text"
                                defaultValue={androidClientId}
                              />
                            </Form.Group>
                            <Form.Group controlId="androidCertUrl">
                              <Form.Label>URL para certificado</Form.Label>
                              <Form.Control
                                name="androidCertUrl"
                                type="text"
                                defaultValue={androidCertUrl}
                              />
                            </Form.Group>
                            <Form.Group controlId="androidBucketName">
                              <Form.Label>Nombre del bucket</Form.Label>
                              <Form.Control
                                name="androidBucketName"
                                type="text"
                                defaultValue={androidBucketName}
                              />
                            </Form.Group>
                          </Card.Body>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Card>
                  </>
                )}
              </>
            )}
            {showSections['apiAccess'] && (
              <>
                <Card>
                  <Accordion.Item eventKey="5">
                    <Card.Header>
                      <Accordion.Button as={Button} variant="block text-start">
                        Acceso API
                      </Accordion.Button>
                    </Card.Header>
                    <Accordion.Body>
                      <Card.Body>
                        {!isUpdate && (
                          <>
                            <Form.Group controlId="generateApiKeys">
                              <Form.Check
                                name="generateApiKeys"
                                label="Generar claves API automáticamente"
                                defaultChecked={generateApiKeys}
                                type="checkbox"
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setGenerateApiKeys(true);
                                  } else {
                                    setGenerateApiKeys(false);
                                  }
                                }}
                              />
                            </Form.Group>
                          </>
                        )}
                        {(isUpdate || !generateApiKeys) && (
                          <>
                            <Form.Group controlId="universityId">
                              <Form.Label>Clave</Form.Label>
                              <Form.Control
                                name="universityId"
                                type="text"
                                value={formData.universityId}
                                onChange={handleChange}
                                readOnly={isUpdate || generateApiKeys}
                              />
                              {errors.universityId && (
                                <span className="error-message">
                                  {errors.universityId}
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group controlId="secretKey">
                              <Form.Label>Secreto</Form.Label>
                              <Form.Control
                                name="secretKey"
                                type="text"
                                value={formData.secretKey}
                                onChange={handleChange}
                                readOnly={isUpdate || generateApiKeys}
                              />
                              {errors.secretKey && (
                                <span className="error-message">
                                  {errors.secretKey}
                                </span>
                              )}
                            </Form.Group>
                          </>
                        )}
                      </Card.Body>
                    </Accordion.Body>
                  </Accordion.Item>
                </Card>
              </>
            )}
            {showSections['versions'] &&
              process.env.REACT_APP_STAGE != 'prod' &&
              versions && (
                <>
                  <Card>
                    <Accordion.Item eventKey="6">
                      <Card.Header>
                        <Accordion.Button
                          as={Button}
                          variant="block text-start"
                        >
                          Versionado
                        </Accordion.Button>
                      </Card.Header>
                      <Accordion.Body>
                        <Card.Body>
                          <Form.Group controlId="portalVersion">
                            <Form.Label>Versión Portal</Form.Label>
                            <SelectVersion
                              name="portalVersion"
                              value={portalVersion}
                              versions={versions['portal']}
                            ></SelectVersion>
                          </Form.Group>
                          <Form.Group controlId="middlewareVersion">
                            <Form.Label>Versión Middleware</Form.Label>
                            <SelectVersion
                              name="awsVersion"
                              value={awsVersion}
                              versions={versions['aws']}
                            ></SelectVersion>
                          </Form.Group>
                        </Card.Body>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card>
                </>
              )}
          </Accordion>
        </Tab>
        {isUpdate && (
          <Tab eventKey="authorization" title="Sistemas fuentes">
            <Accordion defaultActiveKey="0">
              {universityId &&
                authorizations.sort(orderByName).map((item, indx) => (
                  <Card key={indx}>
                    <Accordion.Item eventKey={indx}>
                      <Card.Header>
                        <Accordion.Button
                          as={Button}
                          variant="block text-start"
                        >
                          {item.name}
                        </Accordion.Button>
                      </Card.Header>
                      <Accordion.Body>
                        <Card.Body>
                          <AuthorizationForm
                            code={item.authorizationId}
                            authorization={authorization}
                          />
                        </Card.Body>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card>
                ))}
            </Accordion>
          </Tab>
        )}
        {isUpdate && (
          <Tab eventKey="authentication" title="Autenticación">
            <Accordion defaultActiveKey="0">
              <AuthenticationMain
                authType={authType}
                authStack={authStack}
                authTracking={authTracking}
                data={authentication}
              />
            </Accordion>
            <h3>Servicios de autenticación</h3>
            <Accordion defaultActiveKey="0">
              {showSections['authLdap'] && (
                <AuthenticationLdap
                  id={0}
                  authParams={authParams}
                  authHost={authHost}
                />
              )}
              {showSections['authCrue'] && (
                <AuthenticationCrue
                  id={1}
                  cruePrivateKey={cruePrivateKey}
                  crueUserMap={crueUserMap}
                />
              )}
              {showSections['authSaml'] && (
                <AuthenticationSaml id={2} data={authentication} />
              )}
              {['UNAD', 'unad'].includes(alias)  && showSections['authUnad'] && (
                <AuthenticationUnad id={4} data={authentication} />
              )}

            </Accordion>
            <h3>Servicios de identidad</h3>
            <Accordion defaultActiveKey="0">
              {universityId &&
                identities.sort(orderByName).map((item, indx) => (
                  <Card key={indx}>
                    <Accordion.Item eventKey={indx}>
                      <Card.Header>
                        <Accordion.Button
                          as={Button}
                          variant="block text-start"
                        >
                          {item.name}
                        </Accordion.Button>
                      </Card.Header>
                      <Accordion.Body>
                        <Card.Body>
                          <IdentityForm
                            code={item.identityId}
                            identity={identity}
                          />
                        </Card.Body>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card>
                ))}
            </Accordion>
            <h3>
              {code === 'PS' ? 'Servicio de colectivos' : 'Servicios de roles'}
            </h3>
            <Accordion defaultActiveKey="0">
              {universityId &&
                rolesvcs.sort(orderByName).map((item, indx) => (
                  <Card key={indx}>
                    <Accordion.Item eventKey={indx}>
                      <Card.Header>
                        <Accordion.Button
                          as={Button}
                          variant="block text-start"
                        >
                          {item.name}
                        </Accordion.Button>
                      </Card.Header>
                      <Accordion.Body>
                        <Card.Body>
                          <RolesvcForm
                            code={item.rolesvcId}
                            rolesvc={rolesvc}
                          />
                        </Card.Body>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card>
                ))}
            </Accordion>
            <h3>Mapeo de roles</h3>
            <Accordion defaultActiveKey="0">
              {showSections['platformRoles'] && (
                <Card key={1}>
                  <Accordion.Item eventKey={1}>
                    <Card.Header>
                      <Accordion.Button as={Button} variant="block text-start">
                        {sectionName['platformRoles'][code]}
                      </Accordion.Button>
                    </Card.Header>
                    <Accordion.Body>
                      <Card.Body>
                        <Row>
                          {universityId &&
                            rolemaps.sort(orderByName).map((item, indx) => (
                              <Col sm={6} key={indx}>
                                <RolemapForm
                                  code={item.rolemapId}
                                  label={item.name}
                                  rolemap={rolemap}
                                />
                              </Col>
                            ))}
                        </Row>
                      </Card.Body>
                    </Accordion.Body>
                  </Accordion.Item>
                </Card>
              )}
              {showSections['licenseRoles'] && (
                <Card key={2}>
                  <Accordion.Item eventKey={2}>
                    <Card.Header>
                      <Accordion.Button as={Button} variant="block text-start">
                        Roles del carnet
                      </Accordion.Button>
                    </Card.Header>
                    <Accordion.Body>
                      <Card.Body>
                        <Row>
                          {universityId &&
                            rolesuc.sort(orderByName).map((item, indx) => (
                              <Col sm={6} key={indx}>
                                <SelectUCRoles
                                  code={item.generalId}
                                  name={item.name}
                                  value={roleuc}
                                  items={rolemaps}
                                />
                              </Col>
                            ))}
                        </Row>
                      </Card.Body>
                    </Accordion.Body>
                  </Accordion.Item>
                </Card>
              )}
            </Accordion>
          </Tab>
        )}
        {isUpdate && showSections['general'] && (
          <Tab eventKey="general" title="General">
            {universityId && (
              <GroupList identity="general" items={generals} data={general} />
            )}
          </Tab>
        )}
        {isUpdate && showSections['service'] && (
          <Tab eventKey="service" title="CMS">
            {universityId && (
              <GroupList identity="service" items={services} data={service} />
            )}
          </Tab>
        )}
        {isUpdate && showSections['academic'] && (
          <Tab eventKey="academic" title="AC">
            {universityId && (
              <GroupList
                identity="academic"
                items={academics}
                data={academic}
              />
            )}
          </Tab>
        )}
        {isUpdate && showSections['economic'] && (
          <Tab eventKey="economic" title="EC">
            {universityId && (
              <GroupList
                identity="economic"
                items={economics}
                data={economic}
              />
            )}
          </Tab>
        )}
        {isUpdate && showSections['investigation'] && (
          <Tab eventKey="investigation" title="INV">
            {universityId && (
              <GroupList
                identity="investigation"
                items={investigations}
                data={investigation}
              />
            )}
          </Tab>
        )}
        {isUpdate && showSections['humanResource'] && (
          <Tab eventKey="humanresource" title="RRHH">
            {universityId && (
              <GroupList
                identity="humanresource"
                items={humanresources}
                data={humanresource}
              />
            )}
          </Tab>
        )}
        {isUpdate && showSections['secureTitle'] && (
          <Tab eventKey="securetitle" title="TS">
            {universityId && (
              <GroupList
                identity="securetitle"
                items={securetitles}
                data={securetitle}
              />
            )}
          </Tab>
        )}
        {isUpdate && showSections['notificationsTab'] && (
          <Tab eventKey="notifications" title="Notificaciones">
            {universityId && (
              <NotificationList items={notifications} data={notification} />
            )}
          </Tab>
        )}
        {isUpdate && showSections['credentials'] && (
          <Tab eventKey="credentials" title="Credenciales">
            {universityId && (
              <CredentialList identity="credential" id={universityId} />
            )}
          </Tab>
        )}
        {isUpdate && showSections['config']['show'] && (
          <Tab eventKey="config" title="Config. App">
            {universityId && (
              <>
                {showSections['config']['identifiers']['show'] && (
                  <ConfigIdentifiers
                    code="identifiers"
                    data={configApp}
                    showSections={showSections['config']['identifiers']}
                  />
                )}
                {showSections['config']['colors']['show'] && (
                  <ConfigColors
                    code="colors"
                    data={configApp}
                    showSections={showSections['config']['colors']}
                  />
                )}
                {showSections['config']['homePage']['show'] && (
                  <ConfigHomePage
                    code="homePage"
                    data={configApp}
                    showSections={showSections['config']['homePage']}
                  />
                )}
                {showSections['config']['currency']['show'] && (
                  <ConfigCurrency
                    code="currency"
                    data={configApp}
                    showSections={showSections['config']['currency']}
                  />
                )}
                {showSections['config']['login']['show'] && (
                  <ConfigLogin
                    code="login"
                    data={configApp}
                    showSections={showSections['config']['login']}
                  />
                )}
                {showSections['config']['publicAccessAuth']['show'] && (
                  <ConfigPublicAccessAuth
                    code="publicAccessAuth"
                    data={configApp}
                    showSections={showSections['config']['publicAccessAuth']}
                  />
                )}
                {showSections['config']['publicAccessConf']['show'] && (
                  <ConfigPublicAccessConf
                    code="publicAccessConf"
                    data={configApp}
                    showSections={showSections['config']['publicAccessConf']}
                  />
                )}
                {showSections['config']['payment']['show'] && (
                  <ConfigPayment
                    code="payment"
                    data={configApp}
                    showSections={showSections['config']['payment']}
                  />
                )}
                {showSections['config']['twitter']['show'] && (
                  <ConfigTwitter
                    code="twitter"
                    data={configApp}
                    showSections={showSections['config']['twitter']}
                  />
                )}
                {showSections['config']['attendance']['show'] && (
                  <ConfigAttendance
                    code="attendance"
                    data={configApp}
                    showSections={showSections['config']['attendance']}
                  />
                )}
                {showSections['config']['enrollment']['show'] && (
                  <ConfigEnrollment
                    code="enrollment"
                    data={configApp}
                    showSections={showSections['config']['enrollment']}
                  />
                )}
                {showSections['config']['ts']['show'] && (
                  <ConfigTS
                    code="ts"
                    data={configApp}
                    showSections={showSections['config']['ts']}
                  />
                )}
                {showSections['config']['order']['show'] && (
                  <ConfigOrder
                    code="order"
                    data={configApp}
                    showSections={showSections['config']['order']}
                  />
                )}
                {showSections['config']['economic']['show'] && (
                  <ConfigEconomic
                    code="economic"
                    data={configApp}
                    showSections={showSections['config']['economic']}
                  />
                )}
                {showSections['config']['training']['show'] && (
                  <ConfigTraining
                    code="training"
                    data={configApp}
                    showSections={showSections['config']['training']}
                  />
                )}
                {showSections['config']['absences']['show'] && (
                  <ConfigAbsences
                    code="absences"
                    data={configApp}
                    showSections={showSections['config']['absences']}
                  />
                )}
                {showSections['config']['alumni']['show'] && (
                  <ConfigAlumni
                    code="alumni"
                    data={configApp}
                    showSections={showSections['config']['alumni']}
                  />
                )}
                {showSections['config']['languages']['show'] && (
                  <ConfigLanguages
                    code="languages"
                    data={configApp}
                    showSections={showSections['config']['languages']}
                  />
                )}
                {showSections['config']['widgets']['show'] && (
                  <ConfigWidgets
                    code="widgets"
                    data={configApp}
                    showSections={showSections['config']['widgets']}
                  />
                )}
                {showSections['config']['tutors']['show'] && (
                  <ConfigTutors
                    code="tutors"
                    data={configApp}
                    showSections={showSections['config']['tutors']}
                  />
                )}
                {showSections['config']['documentManager']['show'] && (
                  <ConfigDocumentManager
                    code="documentManager"
                    data={configApp}
                    showSections={showSections['config']['documentManager']}
                  />
                )}
                {showSections['config']['virtualCard']['show'] && (
                  <ConfigVirtualCard
                    code="virtualCard"
                    data={configApp}
                    showSections={showSections['config']['virtualCard']}
                  />
                )}
                {showSections['config']['cdu']['show'] && (
                  <ConfigCDU
                    code="cdu"
                    data={configApp}
                    showSections={showSections['config']['cdu']}
                  />
                )}
                {showSections['config']['rateAndShare']['show'] && (
                  <ConfigRateAndShare
                    code="rateAndShare"
                    data={configApp}
                    showSections={showSections['config']['rateAndShare']}
                  />
                )}
                {showSections['config']['download']['show'] && (
                  <ConfigDownload
                    code="download"
                    data={configApp}
                    showSections={showSections['config']['download']}
                  />
                )}
                {showSections['config']['screens']['show'] && (
                  <ConfigScreens
                    code="screens"
                    data={configApp}
                    showSections={showSections['config']['screens']}
                  />
                )}
              </>
            )}
          </Tab>
        )}
        {isUpdate &&
          process.env.REACT_APP_STAGE != 'prod' &&
          showSections['catalog']['show'] && (
            <Tab eventKey="catalog" title="Cargar catálogo">
              {showSections['catalog']['actions']['show'] && (
                <CatalogActions
                  code="actions"
                  data={configApp}
                  showSections={showSections['catalog']['actions']}
                ></CatalogActions>
              )}
              {/* {showSections['catalog']['logs']['show'] && (
              <CatalogLogs code='logs' data={configApp} showSections={showSections['catalog']['logs']} ></CatalogLogs>
            )} */}
            </Tab>
          )}
      </Tabs>
    </Form>
  );
};
export default UniversityItem;
