import React, { useState } from 'react';
import { Form, Button, Row, Col, Card, Accordion, InputGroup } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { getFormValue } from '../../helpers/formdata';
import { isAdminRole } from '../../helpers/roles';

const AuthenticationUnad = ({ id, data }) => {
  const [showClientId, setShowClientId] = useState(false);
  const [showHashKey, setShowHashKey] = useState(false);

  return (
    <Card>
      <Accordion.Item eventKey={id}>
        <Card.Header>
          <Accordion.Button as={Button} variant="block text-start">
            UNAD
          </Accordion.Button>
        </Card.Header>
        <Accordion.Body>
          <Card.Body>

            <Row>
              <Col>
                <Form.Group controlId={`authentication-unad-username`}>
                  <Form.Label>Client name</Form.Label>
                  <InputGroup>
                    <Form.Control
                      name={`authentication-unad-username`}
                      defaultValue={getFormValue(data, 'unad', 'username')}
                      type={showClientId ? 'text' : 'password'}
                      readOnly={!isAdminRole()}
                    />
                    {isAdminRole() && (
                      <Button
                        variant="eye"
                        onClick={() => setShowClientId(!showClientId)}
                      >
                        {showClientId ? <FaEyeSlash /> : <FaEye />}
                      </Button>
                    )}
                  </InputGroup>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId={`authentication-unad-keyAES128`}>
                  <Form.Label>Key UNAD AES128</Form.Label>
                  <InputGroup>
                    <Form.Control
                      name={`authentication-unad-keyAES128`}
                      defaultValue={getFormValue(data, 'unad', 'keyAES128')}
                      type={showHashKey ? 'text' : 'password'}
                      readOnly={!isAdminRole()}
                    />
                    {isAdminRole() && (
                      <Button
                        variant="eye"
                        onClick={() => setShowHashKey(!showHashKey)}
                      >
                        {showHashKey ? <FaEyeSlash /> : <FaEye />}
                      </Button>
                    )}
                  </InputGroup>
                </Form.Group>
              </Col>

            </Row>

            <Form.Group controlId={`authentication-unad-appDomain`}>
              <Form.Label>App domain</Form.Label>
              <Form.Control
                name={`authentication-unad-appDomain`}
                defaultValue={getFormValue(data, 'unad', 'appDomain')}
                type="text"
              />
            </Form.Group>
            <Form.Group controlId={`authentication-unad-deviceDomain`}>
              <Form.Label>Device domain</Form.Label>
              <Form.Control
                name={`authentication-unad-deviceDomain`}
                defaultValue={getFormValue(data, 'unad', 'deviceDomain')}
                type="text"
              />
            </Form.Group>
          </Card.Body>
        </Accordion.Body>
      </Accordion.Item>
    </Card>
  );
};

export default AuthenticationUnad;
