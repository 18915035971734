import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

import { getFormValue } from '../../helpers/formdata';

const ConfigAlumni = ({ code, data, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">Alumni</h2>
      <Row>
        {showSections['useAlumniRolFilters'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-useAlumniRolFilters`}>
              <Form.Check
                defaultChecked={getFormValue(data, code, 'useAlumniRolFilters')}
                name={`configApp-${code}-useAlumniRolFilters`}
                type="checkbox"
                label="Noticias y eventos sólo accesibles para el rol Alumni"
              />
            </Form.Group>
          </Col>
        )}
        {showSections['largeForm'] && (
          <Col sm={12}>
            <Form.Group controlId={`configApp-${code}-largeForm`}>
              <Form.Check
                defaultChecked={getFormValue(data, code, 'largeForm')}
                name={`configApp-${code}-largeForm`}
                type="checkbox"
                label="Formulario largo"
              />
              <div>
                Si se marca, se mostrará el formulario largo para gestión manual
              </div>
            </Form.Group>
          </Col>
        )}
        {showSections['captchaKey'] && (
          <Row style={{ marginTop: '1rem' }}>
            <Col sm={8}>
              <Form.Group controlId={`configApp-${code}-captchaKey`}>
                <Form.Label>Token API Captcha</Form.Label>
                <Form.Control
                  name={`configApp-${code}-captchaKey`}
                  defaultValue={getFormValue(data, code, 'captchaKey')}
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>
        )}
      </Row>
    </div>
  );
};
export default ConfigAlumni;
