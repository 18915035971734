import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Amplify, Auth } from 'aws-amplify';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import {
  legacy_createStore as createStore,
  applyMiddleware
} from 'redux';
import { Provider } from 'react-redux';
import { thunk } from 'redux-thunk';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import rootReducer from './reducers/root';
import { authenticated } from './actions/auth';
import { LoginRequired } from './components';
import { getCache } from './helpers/cache';
import { logout } from './helpers/auth';
import { Home } from './containers';
import { Universities } from './containers';
import { LoginSaml } from './containers';
import { Logout } from './containers';
import { Redirect } from './containers';
import { awsConfig } from './config';

const main = ReactDOM.createRoot(document.getElementById('main'));
let user = getCache('user');
let store = createStore(rootReducer, applyMiddleware(thunk));

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: awsConfig.cognito.REGION,
    userPoolId: awsConfig.cognito.USER_POOL_ID,
    identityPoolId: awsConfig.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: awsConfig.cognito.APP_CLIENT_ID,
    oauth: {
      domain: awsConfig.cognito.DOMAIN,
      redirectSignIn: awsConfig.cognito.REDIRECT_SIGN_IN,
      redirectSignOut: awsConfig.cognito.REDIRECT_SIGN_OUT,
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: 'admin',
        endpoint: awsConfig.apiGateway.URL,
        region: awsConfig.apiGateway.REGION,
      },
    ],
  },
});
Auth.currentSession().catch(() => {
  store.dispatch(logout());
});
if (user) {
  store.dispatch(authenticated(user));
}

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/logout" element={<Logout />} />
          <Route path="/login" element={<LoginSaml />} />
          <Route path="/redirect" element={<Redirect />} />
          <Route element={<LoginRequired />}>
            <Route path="/" element={<Home />} />
            <Route path="/universities" element={<Universities />} />
            <Route path="/universities/:id" element={<Universities />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};

if (main) {
  main.render(<App />);
}
