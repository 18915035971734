import { API } from 'aws-amplify'
import * as types from './actionTypes'
import {v4 as uuidv4} from 'uuid'

export const fetchUniversityBegin = () => ({
  type: types.FETCH_UNIVERSITY_BEGIN,
})
export const fetchUniversityList = (items) => ({
  type: types.FETCH_UNIVERSITY_LIST,
  payload: { items },
})
export const fetchUniversityItem = (item) => ({
  type: types.FETCH_UNIVERSITY_ITEM,
  payload: { item },
})
export const fetchUniversityFailure = (error) => ({
  type: types.FETCH_UNIVERSITY_FAILURE,
  payload: { error },
})
export const fetchVersionsOk = (versions) => ({
  type: types.FETCH_VERSIONS,
  payload: { versions },
})
export const fetchVersionsFailure = (error) => ({
  type: types.FETCH_VERSIONS_FAILURE,
  payload: { error },
})
export const fetchUniversities = () => {
  const randomCode = uuidv4()
  const params = {
    queryStringParameters: {
      randomCode: randomCode
    }
  }
  return (dispatch) => {
    dispatch(fetchUniversityBegin())
    return API.get('admin', '/universities', params)
      .then((data) => dispatch(fetchUniversityList(data.Items)))
      .catch((err) => dispatch(fetchUniversityFailure(err)))
  }
}
export const fetchUniversity = (id) => {
  const randomCode = uuidv4()
  const params = {
    queryStringParameters: {
      randomCode: randomCode
    }
  }
  return (dispatch) => {
    dispatch(fetchUniversityBegin())
    return API.get('admin', `/universities/${id}`, params)
      .then((data) => dispatch(fetchUniversityItem(data.Item)))
      .catch((err) => dispatch(fetchUniversityFailure(err)))
  }
}
export const createUniversity = (data) => {
  const randomCode = uuidv4()
  const params = {
    queryStringParameters: {
      randomCode: randomCode
    },
    body: data
  }
  return (dispatch) => {
    dispatch(fetchUniversityBegin())
    return API.post('admin', `/universities`, params)
      .then(() => dispatch(fetchUniversities()))
      .catch((err) => dispatch(fetchUniversityFailure(err)))
  }
}
export const updateUniversity = (data) => {
  const randomCode = uuidv4()
  const params = {
    queryStringParameters: {
      randomCode: randomCode
    },
    body: data
  }
  return (dispatch) => {
    dispatch(fetchUniversityBegin())
    return API.put('admin', `/universities`, params)
      .then(() => dispatch(fetchUniversities()))
      .catch((err) => dispatch(fetchUniversityFailure(err)))
  }
}
export const duplicateUniversity = (id, data) => {
  const randomCode = uuidv4()
  const params = {
    queryStringParameters: {
      randomCode: randomCode
    },
    body: {
      universityId: id
    }
  }
  const generateApiKeys = data.generateApiKeys
  if (generateApiKeys) {
    params.body.generateApiKeys = generateApiKeys
  } else {
    params.body.newUniversityId = data.universityId
    params.body.newSecretKey = data.secretKey
  }
  return (dispatch) => {
    dispatch(fetchUniversityBegin())
    return API.post('admin', `/duplicateuniversity`, params)
      .then(() => dispatch(fetchUniversities()))
      .catch((err) => dispatch(fetchUniversityFailure(err)))
  }
}
export const deleteUniversity = (id) => {
  const randomCode = uuidv4()
  const params = {
    queryStringParameters: {
      randomCode: randomCode
    },
    body: {
      universityId: id
    }
  }
  return (dispatch) => {
    dispatch(fetchUniversityBegin())
    return API.del('admin', `/universities`, params)
      .then(() => dispatch(fetchUniversities()))
      .catch((err) => dispatch(fetchUniversityFailure(err)))
  }
}
export const closeSessionUniversity = (id) => {
  const randomCode = uuidv4()
  const params = {
    queryStringParameters: {
      randomCode: randomCode
    },
    body: {
      universityId: id
    }
  }
  return (dispatch) => {
    dispatch(fetchUniversityBegin())
    return API.post('admin', `/sessioncloseuniversity`, params)
      .then(() => dispatch(fetchUniversities()))
      .catch((err) => dispatch(fetchUniversityFailure(err)))
  }
}
export const fetchVersions = (id) => {
  const randomCode = uuidv4()
  const params = {
    queryStringParameters: {
      randomCode: randomCode
    }
  }
  return (dispatch) => {
    dispatch(fetchUniversityBegin())
    return API.get('admin', `/versions`, params)
      .then((data) => dispatch(fetchVersionsOk(data.Items)))
      .catch((err) => dispatch(fetchVersionsFailure(err)))
  }
}
